<script>
import liff from '@line/liff'

import GlobalHeader from '@/components/GlobalHeader.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'

export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter
  },
  data() {
    return {
      line_id_token: '',
      isLiffOpen: false,
    }
  },
  created(){
    this.isLiffOpen = liff.isInClient()
    liff
      .init({ liffId: process.env.VUE_APP_LIFF_ID })
      .then(() => {
        this.line_id_token = liff.getIDToken()
      })
      .catch((err) => {
        // Error happens during initialization
        console.log('App',err.code, err.message);
      });
  },
}
</script>

<template>
  <div>
    <global-header
      :isLiffOpen="isLiffOpen"
      class="site-header"
    />
    <router-view 
      class="main"
      :isLiffOpen="isLiffOpen"
      :id_token="line_id_token"
    />
    <global-footer />
  </div>
</template>

<style>
@import "../public/resources/reset.css";
@font-face {
  font-family: "BrandonGrotesque Regular";
  src: url("./assets/fonts/BrandonGrotesque-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "BrandonGrotesque Bold";
  src: url("./assets/fonts/BrandonGrotesque-Bold.woff2") format("woff2");
}

#app {
  font-family:
    "BrandonGrotesque Regular",
    "BrandonGrotesque Bold",
    YuGothic,
    "游ゴシック体",
    sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  color: #777;
}
* ::before ::after{
  border-style: solid;
  border-width: 0;
}
.main{
  padding-top: 70px;
  max-width: 414px;
  margin: 0 auto;
}
.site-header {
  position: fixed;
}
</style>

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName 'home' */ "../views/About.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: 'about' */ "../views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: 'contact' */ "../views/Contact.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () =>
      import(/* webpackChunkName: 'terms' */ "../views/Terms.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: 'privacy-policy' */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/concierges",
    name: "Concierges",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: 'all-concierges' */ "../views/concierges/Concierges.vue"
      ),
  },
  {
    path: "/flow/item-suggestions",
    name: "ItemSugesstions",
    component: () =>
      import(
        /* webpackChunkName: 'flow-item-suggestions' */ "../views/flow/FlowItemSuggestions.vue"
      ),
  },
  {
    path: "/flow/skin-check",
    name: "SkinCheck",
    component: () =>
      import(
        /* webpackChunkName: 'skincheck' */ "../views/flow/FlowSkinCheck.vue"
      ),
  },
  {
    path: "/result/skin-check",
    name: "SkinCheckResult",
    props: true /* 遷移解消済 */,
    component: () =>
      import(
        /* webpackChunkName: 'skincheck-result' */ "../views/result/SkinCheckResult.vue"
      ),
  },
  {
    path: "/result/item-suggestions/skincare",
    name: "SkincareItemResult",
    props: true /* 遷移・保持確認済 */,
    component: () =>
      import(
        /* webpackChunkName: 'skincare-result' */ "../views/result/SkincareItemResult.vue"
      ),
  },
  {
    path: "/result/item-suggestions/makeup",
    name: "MakeupItemResult",
    props: true /* 遷移・保持確認済 */,
    component: () =>
      import(
        /* webpackChunkName: 'makeup-result' */ "../views/result/MakeupItemResult.vue"
      ),
  },
  {
    path: "/mykarte",
    name: "Mykarte" /* 遷移・保持確認済 */,
    component: () =>
      import(/* webpackChunkName: 'mydata' */ "../views/Mykarte.vue"),
    props: true,
  },
  {
    path: "/mykarte/:viewGenre",
    component: () =>
      import(/* webpackChunkName: 'mydata' */ "../views/Mykarte.vue"),
  },
  {
    path: "/mykarte/skincare/:concierge",
    component: () =>
      import(/* webpackChunkName: 'mydata' */ "../views/Mykarte.vue"),
  },
  {
    path: "/mykarte/skincare/:concierge/:category",
    component: () =>
      import(/* webpackChunkName: 'mydata' */ "../views/Mykarte.vue"),
  },
  {
    path: "/trend",
    name: "Trend",
    component: () =>
      import(/* webpackChunkName: 'trend' */ "../views/Trend.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // return desired position
    return { top: 0 };
  },
});

export default router;

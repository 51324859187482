<script>
export default {
  name: 'GlobalHeader',
  props:{
    isLiffOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    hamburger() {
      this.isActive = !this.isActive
      if (this.isActive) {
        document.body.style.top = 0
        document.body.style.position = 'absolute'
      } else {
        document.body.style.position = ''
        document.body.style.top = ''
      }
    },
  },
}
</script>

<template>
  <header
    class="header"
    :class="{ header__isActive: isActive }"
  >
    <a
      class="header-button"
      @click="hamburger()"
    >
      <div
        class="header-button-icon"
        :class="{ headerButtonIcon__isActive: isActive }"
      >
        <span />
        <span />
      </div>
    </a>
    <img
      src="@/assets/logos/logo_wide.png"
      class="header-logo"
      :class="{ headerLogo__isActive: isActive }"
    >
    <nav
      class="globalNav"
      :class="{ globalNav__isActive: isActive }"
      @click="hamburger()"
    >
      <div class="globalNav__inner">
        <ul class="main_menu">
          <li>
            <router-link to="/flow/skin-check">
              <img src="@/assets/logos/skin_check.svg">
              <span>
                {{ $t('categorySkinCheck') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link to="/flow/item-suggestions">
              <img src="@/assets/about/service_image.svg">
              <span>
                {{ $t('askConcierges') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link to="/mykarte">
              <img src="@/assets/logos/mykarte.svg">
              <span>
                {{ $t('mykarte') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/trend"
            >
              <img src="@/assets/logos/trend_logo.svg">
              <span>
                {{ $t('trendTitle') }}
              </span>
            </router-link>
          </li>
          <li>
            <router-link to="/concierges">
              <img src="@/assets/logos/concierges.svg">
              <span>
                {{ $t('conciergeTitle') }}
              </span>
            </router-link>
          </li>
        </ul>
        <ul class="footer_menu">
          <li>
            <router-link to="/about">
              ABOUT
            </router-link>
          </li>
          <li>
            <a href="https://justfit.xyz/">
              COMPANY
            </a>
          </li>
          <li>
            <router-link to="/contact">
              CONTACT
            </router-link>
          </li>
          <li>
            <router-link to="/terms">
              TERMS
            </router-link>
          </li>
          <li>
            <router-link to="/privacy-policy">
              PRIVACY&nbsp;POLICY
            </router-link>
          </li>
        </ul>
        <div class="nav_snsIcons">
          <a href="https://www.instagram.com/yuni_cosme">
            <img src="@/assets/logos/instagram_logo.svg">
          </a>
          <a
            v-if="!isLiffOpen"
            href="https://lin.ee/meyjfHf"
          >
            <img src="@/assets/logos/LINE_logo.svg">
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped>
body * {
  box-sizing: border-box;
}
a {
  color: #777;
}
img {
  display: block;
}
.header {
  width: 100%;
  z-index: 91;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(250, 218, 210);
  transition: all 0.4s ease-in-out;
}
.header__isActive {
  background-color: #fff;
}
.header-logo {
  width: 147px;
  padding-top: 26px;
  padding-bottom: 26px;
  transition: all 0.4s ease-in-out;
}
.headerLogo__isActive {
  transform: translateX(calc(50vw - 73px - 20px));
}
.header-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 80px;
  height: 68.16px;
  cursor: pointer;
}
.header-button-icon {
  transition: all 0.4s; /*アニメーションの設定*/
  position: absolute;
  width: 40px;
  left: 20px;
  top: 50%;
}
/* 線の太さ */
.header-button-icon span {
  background-color: #777;
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  transition: all 0.4s;
}
.header-button-icon span:nth-of-type(1) {
  top: -6px;
}
.header-button-icon span:nth-of-type(2) {
  bottom: -6px;
}
/*activeクラスが付与されると線が回転して×に*/
.header-button-icon.headerButtonIcon__isActive span:nth-of-type(1) {
  /* top: 20px;
  left: 16px; */
  transform: translateY(5px) rotate(-20deg);
}
.header-button-icon.headerButtonIcon__isActive span:nth-of-type(2) {
  /* top: 32px;
  left: 16px; */
  transform: translateY(-5px) rotate(20deg);
}
/* navメニュー */
.globalNav {
  opacity: 0;
  position: absolute;
  top: 0;
  left: -100vw;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  padding-top: 68.16px;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
}
.globalNav__isActive {
  opacity: 1;
  left: 0;
}
.globalNav-logo {
  margin-left: auto;
  margin-right: auto;
  width: 147px;
  padding-top: 26px;
  padding-bottom: 26px;
}
.globalNav__inner {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 80px;
  background-color: #fff;
}
.main_menu {
  padding: 120px 0 85px 0;
}
.main_menu li {
  font-size: 24px;
  font-weight: bold;
  line-height: 2.5;
}
.main_menu li:last-child {
  padding-top: 25px;
}
.main_menu li a{
  display: flex;
  align-items: center;
}
.main_menu li a img{
  width: 30px;
}
.main_menu li a span{
  padding-left: 15px;
}
.no_underline {
  text-decoration: none;
}
.footer_menu {
  padding-bottom: 25px;
}
.footer_menu li {
  font-size: 22px;
  line-height: 1.5;
}
.footer_menu li a {
  text-decoration: none;
}
.nav_snsIcons {
  display: flex;
}
.nav_snsIcons img {
  width: 40px;
  padding-right: 20px;
}
</style>

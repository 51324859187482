export default {
  /* ############################################ */
  /* ################## Common ################## */
  /* ############################################ */
  /* --- larger category ---- */
  categorySkinCheck: "肤质检查",
  categorySkincare: "护肤",
  categoryMakeup: "彩妆",

  /* --- smaller category --- */
  categorySkinLotion: "化妆⽔",
  categoryMilkyLotionCream: "乳液・乳霜",
  categoryAllInOne: "一体化化妆品",

  /* --- ingredient --- */
  ingredientCeramide: "神经酰胺",
  ingredientVitaminC: "维生素C",
  ingredientRetinol: "视黄醇",
  ingredientNiacinamide: "烟酰胺",
  ingredientTranexamicAcid: "氨甲环酸",

  /* --- problems --- */
  problemPores: "毛孔",
  problemRoughSkin: "皮肤那个",
  problemOilySkin: "油性肌肤",

  /* --- header --- */
  askConcierges: "咨询接待员",
  mykarte: "我的病历", // TODO: 要検討

  /* ############################################ */
  /* ################ components ################ */
  /* ############################################ */
  /* -------------------- */
  /* ----- Flow Dir ----- */
  /* -------------------- */
  /* ------- question ------ */
  questionBackButton: "上一个",

  /* -- concierge choice  -- */
  conciergeChoiceQuestion: "接受哪个接待员的建议？",
  conciergeChoiceDesc:
    "选择你想咨询的接待员吧。通过回答问题，可以知道接待员推荐的化妆品。",
  conciergeSeeProfile: "查看简介",
  conciergeSeeProfileDetails: "简介详细信息",
  conciergeSelect: "选择此接待员",

  /* ---- Face Chin ---- */
  faceChinQuestion: "你的下巴是什么形状的？",
  faceChinAnswer1: "下巴露出来了",
  faceChinAnswer2: "普通",
  faceChinAnswer3: "无下巴",

  /* ---- Face Chiseled ---- */
  faceChiseledQuestion: "雕刻很深吗？",
  faceChiseledAnswer1: "雕刻得很深",
  faceChiseledAnswer2: "雕刻得浅",

  /* ---- Face Forehead ---- */
  faceForeheadQuestion: "你的额头是什么形状？",
  faceForeheadAnswer1: "你的额头突出来了",
  faceForeheadAnswer2: "普通",
  faceForeheadAnswer3: "扁平的额头",

  /* ---- Face Parts Placement ---- */
  facePartsPlacementQuestion: "哪个更靠近你的脸？",

  /* ---- Face Shape ---- */
  faceShapeQuestion: "选择您的脸型",
  faceShapeAnswer1: "卵圆形",
  faceShapeAnswer2: "圆形",
  faceShapeAnswer3: "长圆形",
  faceShapeAnswer4: "钻石形",
  faceShapeAnswer5: "三角形",
  faceShapeAnswer6: "方形",

  /* ---- Skin Condition ---- */
  skinConditionQuestion: "请告诉我你的皮肤状况",
  skinConditionAnswer1: "健康的皮肤",
  skinConditionAnswer2: "颜色不均匀*",
  skinConditionAnswer3: "细纹",
  skinConditionAnswer4: "毛孔",
  skinConditionFootnote: "* 也包括“暗沉、雀斑、熊”等。",

  /* ---- Skin Check Confirm ---- */
  skinCheckConfirmQuestion:
    "在最近的诊断中，你的皮肤如下。可以在这里提出建议吗？",
  skinCheckConfirmAnswer1: "是的",
  skinCheckConfirmAnswer2: "再检查一次肤质",

  /* -------------------------- */
  /* ---- other components ---- */
  /* -------------------------- */
  /* ---- result filter ---- */
  resultFilterPrice: "价格",
  resultFilterCategoryDetails: "详细类别",
  resultFilterNeedsIngredient: "特别想放入成分",
  resultFilterProblemsBeSolved: "想解决的肌肤烦恼",

  /* ---- price slider ---- */
  priceSliderTitle: "金额",

  /* ---- item details ---- */
  /* ---- makeup tools ---- */
  itemsLowestPrice: "看最低价",
  itemsUpdate: "更新",
  itemsAttention: "* 请注意，显示的价格可能不准确。",
  itemsOfficialUrl: "▼ 转到主页",
  itemsConciergeComment: "- 接待员评论 -",
  itemsShowMore: "▼ 看得更多",
  itemsClose: "▲ 关闭",
  itemsOtherComments: "也推荐其他的接待员",
  itemDetails: "商品详情",
  itemCategory: "分类",
  itemBrand: "品牌",
  itemName: "商品名称",
  itemMakeupTools: "使用的化妆工具",
  /* ----- item list ----- */
  itemRetailPrice: "建议零售价",

  /* ------------------------------- */
  /* ---- Kazunosuke components ---- */
  /* ------------------------------- */
  /* ---- kazunosuke item details ---- */
  kazunosukeItemExplanation: "商品说明",
  kazunosukeItemBlog: "博客上的介绍报道",
  kazunosukeItemBlogHere: "在这里",

  /* ---- kazunosuke item list ---- */
  kazunosukeItemResultTitle: "没有符合我们条件的商品。",
  kazunosukeItemsShowMore: "看得更多",

  /* ---- kazunosuke result ---- */
  /* ----- skincare result ----- */
  resultToMykarte1: "所有关于你皮肤的信息",
  resultToMykarte2: "你可以记录下来",

  /* ############################################ */
  /* ################### Views ################## */
  /* ############################################ */
  /* --- common --- */
  startSkinCheckButton: "接受皮肤检查",

  /* ------------------------------- */
  /* ------------ Flows ------------ */
  /* ------------------------------- */
  /* --- Flow Skin check --- */
  flowSkinCheckSubTitle: "美容皮肤科医师监修",
  flowSkinCheckDesc:
    "通过回答你现在的皮肤状态和烦恼等问题，分析皮肤的状态。理解自己的皮肤状态，帮助找到适合皮肤的化妆品。",
  flowSkinCheckSupervisor: "- 监修 -",
  hamanoAffiliatedEx: "泰蒂斯横滨美容皮肤科 院长",

  /* --- Flow Item suggestions --- */
  flowItemSuggestionsSubTitle: "找到适合你皮肤的化妆品",
  flowItemSuggestionsDesc1:
    "根据皮肤检查的结果，可以选择护肤化妆，接受接待员的建议。",
  flowItemSuggestionsDesc2:
    "回答关于你现在的皮肤状态和烦恼，商品的价格带等的问题，帮助你找到适合你皮肤的化妆品。",
  flowItemSuggestionsNoResultDesk1: "还没有皮肤检查的结果。",
  flowItemSuggestionsNoResultDesk2:
    "为了寻找适合顾客皮肤的护肤化妆品，首先了解自己的皮肤是很重要的。",
  flowItemSuggestionsNoResultDesk3: "首先试着检查一下皮肤吧。",

  /* ------------------------------- */
  /* ----------- Results ----------- */
  /* ------------------------------- */
  /* --- Result skin check --- */
  resultSkinCheckToSkincareSuggestions: "护肤建议",
  resultSkinCheckToMakeupSuggestions: "化妆提案",
  resultSkinCheckToIngredientCheck: "成分判定",

  /* --- Result skin care --- */
  resultSkinCareGetMainConciergeTitle: "推荐给你的商品在这里。",
  resultSkinCareNoMainConciergeDesc:
    "在您咨询的内容中，没有选择的接待员推荐的商品。我提议其他接待员推荐的商品。",
  resultSkinCareNoItemsDesc1: "非常抱歉。",
  resultSkinCareNoItemsDesc2: "没有符合顾客条件的推荐商品。",
  resultSkinCareNoItemsDesc3: "请改变条件后再和接待员商量。",
  resultSkinCareAskConciergeAgain: "再一次从接待员那里接受推荐商品的提案",

  /* --- Result makeup --- */
  resultMakeupTitle: "推荐给你的化妆商品，我会综合推荐的！",
  resultMakeupNoItemsDesc1:
    "如果是您回答的条件的话，这个范畴没有接待员推荐的商品。",

  /* ------------------------------- */
  /* ----------- MyKarte ----------- */
  /* ------------------------------- */
  // MyKarte Areas
  /* --- MyKarte skin check --- */
  mykarteSkinCheckNoResultDesc1: "还没有接受皮肤检查。",
  mykarteSkinCheckNoResultDesc2: "首先了解自己的皮肤是很重要的。",
  mykarteSkinCheckNoResultDesc3: "试着接受皮肤检查吧。",
  mykarteSkinCheckDesc1: "人的皮肤会随着季节而变化。",
  mykarteSkinCheckDesc2: "要不要接受皮肤检查？",

  /* --- MyKarte skin care message --- */
  /* --- MyKarte Kazunosuke skin care message --- */
  mykarteSkinCareToItemSuggestions: "和接待员商量",

  /* --- MyKarte makeup --- */
  mykarteMakeupNoResultDesc1: "还没有接受化妆商品的咨询。",
  mykarteMakeupNoResultDesc2: "你问一下接待员吧。",

  /* --- MyKarte main --- */
  mykarteMainDesc: "在这里可以确认你的皮肤数据和接待员提议的项目。",

  /* --------------------------- /*
  /* ------- Other pages ------- */
  /* --------------------------- */
  /* --- privacy policy --- */
  privacyPolicyTitle: "隐私政策",

  /* --- terms of use --- */
  termsTitle: "使用条款",

  /* ----- Contact ----- */
  contactTitle: "联系我们",
  sentence1: "请从这里咨询YUNI COSME。",
  sentence2:
    "通常在3个工作日内由负责人联系。根据内容也有不能回答的情况。请谅解。",
  caution: "※不能接受对接待员的个别咨询，请事先谅解",
  contactButton: "联系我们",

  /* ------ Trend ------ */
  trendTitle: "趋势",
  guideToItemSuggestion: "试着寻找适合自己皮肤的化妆品吧",
  guideToItemSuggestionButton: "接受接待员的推荐商品的提案",

  /* --------------------------- /*
  /* -------- Concierges ------- */
  /* --------------------------- */
  conciergeTitle: "接待员一览",

  /* ------ common ----- */
  profile: "profile",
  comment: "comment",

  /* --- kazunosuke --- */
  kazunosukeTitle: "化学老师教美",
  kazunosukeName: "kazunosuke",
  kazunosukeDescription1:
    "アトピーで肌荒れに悩んだ経験から美容や化粧品に関心を持ち、大学院等で化粧品や界面活性剤について専門で研究。",
  kazunosukeDescription2:
    "2013年頃より各種SNSにて化粧品の成分解析や様々な美容情報の発信を開始。",
  kazunosukeDescription3:
    "アメブロ月間最高500万PV、Twitterフォロワー21.7万人、YouTube登録者33.5万人。",
  kazunosukeDescription4:
    "「美容化学者」として美容関連著書を10冊以上執筆し、敏感肌向けオリジナル化粧品「CeraLabo」の製品設計＆企画開発も手掛ける。",
  kazunosukeDescription5:
    "最終学歴：横浜国立大学院卒・環境学修士　※博士課程後期中退",
  kazunosukeDescription6:
    "化粧品企画開発・広告指導顧問　株式会社セララボ　代表取締役",

  /* ------ manami ---- */
  manamiTitle: "トータルビジュアルプロデューサー",
  manamiName: "MANAMI",
  manamiShort1:
    "海外でのモデル経験を活かし、日本に帰国後はヘアメイク、ファッション、歩き方など「見た目に関するすべてのプロデュース」を手掛ける。",
  manamiShort2:
    "なりたいもの目指すものと本人が持つ素質を絶妙に見極めブランディングすることを得意とする。",
  manamiShort3: "顧客は芸能人、政治家、専門家、ミスコンテストの出場者など。",
  manamiDescription1:
    "主な著書は「メイクの超基本テクニック（マイナビ）」「一重奥二重さんの大人メイク（タツミムック）」など全６冊。",
  manamiDescription2:
    "40歳を目前に美容業界や芸能界の人々が法律知識を持つ必要性を感じ、プレーヤー経験者の弁護士を目指して大学受験し合格。中央大学法学部法律学科４年に在籍中。",
  manamiComment1:
    "はじめましての方も、よく知って下さる方もご覧いただきありがとうございます。",
  manamiComment2:
    "私はプロとして「使ってみて納得した」「自腹でも買いたい」「モデルさんや大切な人たちに使ってもらいたい」製品だけを選んでメイクボックスに入れています。",
  manamiComment3:
    "私自身は超がつく乾燥肌、そして４０歳を過ぎた年齢肌、少しの敏感肌ととても面倒な肌質の持ち主ですが、だからこそお肌悩みがある方々のための化粧品選びはとても慎重に行っているつもりです！",
  manamiComment4:
    "毎日が仕事と大学と勉強で忙しい日々を送っており、とっても根が面倒くさがりなので時短コスメやオールインワンコスメが大好きですので、ズボラできるコスメがお好きな方は是非是非私を参考にしていただければと思います！",
  manamiComment5:
    "働いているかどうか、子供がいるかどうか関係なくほとんどの方が基本的に忙しい日々を送っていると思います。時間がある時のためのスペシャルケアももちろん披露していきますが、毎日ケアは頑張りすぎなくて良いんです。自分にフィットするアイテム選びをしていただければ手間が減らせますからね。軽率にきれいになってしまいましょう！笑",

  /* ------- eri ------ */
  eriTitle: "专业化妆师",
  eriName: "Eri",
  eriShort1: "カリフォルニア州で美容師免許を取得後、美容院に勤務。",
  eriShort2:
    "日本に帰国後ヘアメイクに転身し、現在はサロン経営の傍ら広告やファッション業界でヘアメイクを担当",
  eriDescription1:
    "カリフォルニアで10代後半を過ごし、すっぴんでパジャマの状態でジャージしか着ていないライフスタイルだった、ずぼらな私がかれこれ10年以上美容業界で仕事をし、細々とサロンも営業させて頂いております。",
  eriDescription2:
    "コスメ紹介を通じていかに楽に、簡単に、自分に満足できる顔、髪型を手に入れられるかをお伝えしていきたいと思っております。",
  eriDescription3:
    "日本の女性は、みなさん器用でメイクも上手く、肌も綺麗と世界的に高い評価を受けていると思います。",
  eriDescription4:
    "その事を誇りに、よりハッピーな、個々のスタイルを見つけて楽しい生活をおくれるお手伝い出来る事を楽しみにしてます。",
  eriComment1: "【肌質】",
  eriComment2: "混合肌。",
  eriComment3:
    "目元、口元は乾燥しやすく、鼻筋、おでこのテカりはすぐ出て来てしまう、厄介な肌質で、叔父、兄が重度のアトピーで、自分自身も免疫力が弱まるとアトピーぽい症状が出てしまいます。",
  eriComment4: "【ライフスタイル】",
  eriComment5:
    "撮影の現場が不定期かつ、時間も不規則なので、肌荒れや体調は崩しやすく、お弁当や外食も多いので、休日は犬と一緒にのんびり過ごしたり家で自炊して食べる事が多いです。",
  eriComment6: "【どんな目線で製品紹介したいか】",
  eriComment7:
    "広告（主にCM)やファッションのLook撮影やカタログの撮影で実際使ってモデルさんに喜ばれるコスメや、周りの美容業界の方にオススメされた製品など、コスパや成分だけではないトレンドや女子力を刺激するコスメのご紹介をしていきたいです。",
  eriComment8:
    "流行っている物はもちろん、実際にプロの方が多く使っている物や長年人気なコスメの紹介も出来ればと思っております。",

  /* --- Ayako --- */
  ayakoName: "Ayako",
  ayakoShort1:
    "スキンケア商品を主とするコスメブランドにて、営業と商品企画を経験。",
  ayakoShort2:
    "その後、大手エステティック企業や香水メーカーでの事業運営を通じ、今までに100以上のアイテムを世に送り出してきた。",
  ayakoShort3:
    "2019年4月に独立後、10年に渡る美容業界の経験を活かし、新規コスメブランドのプロデュースやサポートを行っている。",
  ayakoDescription1:
    "ポーラ・オルビスホールディングス傘下メーカーの化粧品の営業・企画開発職を経て、TBCグループ株式会社に入社。",
  ayakoDescription2:
    "リテール事業の立ち上げから美容・健康食品領域の企画開発、大手コンビニのPB製品などのコラボレーション事業に従事。",
  ayakoDescription3: "その後フレグランスメーカーを経て2019年より独立。",
  ayakoDescription4:
    "これまでスキンケア中心に100を超える製品企画に携わりオンライン・オフライン流通に展開。",
  ayakoDescription5:
    "現在はコスメ開発コンサルタントとして、美容関連商品の企画開発から事業立ち上げ、人材育成まで美容業界に関わる幅広い業務をサポートしています。",
  ayakoComment1: "ご訪問いただきありがとうございます。",
  ayakoComment2:
    "加齢、食事、睡眠、生理周期やストレス、環境変化など肌はさまざまな影響を受けて日々変化します。",
  ayakoComment3:
    "そのため、ある程度の効果を感じながらも心地よく、変化に左右されずに安心して使えることが化粧品の役割として大切なことだと思っています。",
  ayakoComment4:
    "これまで10年間、自身の肌だけではなく数百回行ってきた製品開発モニターでの知見などをもとにおすすめしたいアイテムをご紹介したいと思っています。",

  /* --- Maya --- */
  mayaName: "Maya",
  mayaShort1:
    "ミスインターナショナル、ミスユニバース、ミススプラナショナル、ミス日本etc...など日本大会出場",
  mayaShort2: "ワンホンアジアスーパーチャレンジ 世界大会5位",
  mayaShort3: "ミススーパータレント インザワールド 世界大会出場",
  mayaShort4: "ミスアジア日本代表 世界大会出場…etc",
  mayaShort5:
    "ミスコン出場の傍らエステティシャン、美容部員として日本・韓国で働く",
  mayaShort6: "モデル、キャンペーンガール、ラジオなど多数",
  mayaDescription1: "こんにちは！",
  mayaDescription2: "Mayaです。",
  mayaDescription3:
    "私は幼稚園の頃から化粧品に興味を持つようになり、高校生の頃には成分まで調べるようになりました。",
  mayaDescription4:
    "顔の右側は商品A、左側は商品Bというように、自分で使ってみて効果を検証するのが趣味で、業務用の肌チェックをする機械まで家にあったりします（もはやマニア・・・笑）",
  mayaDescription5:
    "おそらく、ドラッグストアやデパコスの商品はだいたい制覇しました笑",
  mayaDescription6:
    "世の中の商品をみているとCMとかで人気芸能人を起用して人気のコスメでも、使ったその瞬間はいいけど・・・」というような、長い目でみたら肌への負担が大きい商品も結構あります。",
  mayaDescription7:
    "使った瞬間はよく見えるので、成分の知識を持っていないとこういう商品の良し悪しは判断ができません。",
  mayaDescription8:
    "私のマニアぶりを知る友達からオススメのコスメを聞かれることが多かったので、少しでも私の知識や趣味が皆さんの役に立つならば、、、と思って作ったのがこのサービスです。",
  mayaDescription9: "私の美容のコンセプトは",
  mayaDescription10:
    "「全てのメイクも美しい肌があってこそ。健康的な肌を保って、ずっと長くナチュラルに美しく」です。",
  mayaDescription11:
    "どの記事・レビューもきちんとした知識に基づいて、広告費などをもらうことなく本音を書いています。",
  mayaDescription12:
    "皆さんのコスメ選びや、健康的な肌を保つのに少しでもお役に立てればとっても嬉しいです！",

  /* --- Nico --- */
  nicoName: "Nico",
  nicoShort1:
    "外資系化粧品メーカーでの美容部員、メイクアップアーティスト、ブランドトレーナー、コスメ専門ECサイトバイヤー等25年以上に渡る化粧品業界での経験と、30歳すぎに経験した自身の肌荒れを皮切りに、不妊治療経験、更年期の劇的なカラダの変化など様々な実体験から「肌とカラダとココロを活かすナチュラルなトータルセルフケア」を実現するオーガニック・ナチュラルコスメをはじめ心地よく暮らすための衣食住の提案を得意とする。",
  nicoDescription1: "セルフケアサロン「やさしい暮らしと肌の研究所」代表",
  nicoComment1: "こちらのページにご訪問いただきありがとうございます。",
  nicoComment2:
    "長く化粧品業界を眺めてきた私が行き着いたのは、「スキンケアは人生をうるおわせる」ということ。",
  nicoComment3:
    "そして、そのスキンケアになくてはならない「コスメ」は、人の生き方を左右するほど私たちにとって大切な人生のパートナーです。",
  nicoComment4:
    "とかくカラダにダメージを与えるコトやモノが多くなった今の時代には、ほんの数分のスキンケアという行為がもっとも自分を近くに感じ癒すことのできる愛すべき時間だから、毎日たくさん肌と会話してほしいなと思います。",
  nicoComment5:
    "肌はあなたのカラダとココロの小さな変化をつぶさに捉え、いつでもたくさんのことを教えてくれます。",
  nicoComment6:
    "そんな肌に日々感謝しながら、カラダごと喜ぶあなただけの運命のコスメに出会ってくださいね。",

  /* --- peki --- */
  pekiName: "ペキ",
  pekiShort1:
    "21歳で美容師に就いてから、あっという間にアラフォーに近づいておりますが、ヘアメイク、エステを通じ美容がずっと身近にあります。",
  pekiShort1:
    "今はメイクの現場からは離れて社会人をやっておりますが、美容好きが高じて縁あって今回参加させて頂く運びとなりました。",
  pekiDescription1:
    "自分自身も炎症により化粧も出来ないひどい肌荒れに悩まされていた時期もありながら、令和をたくましく生きるお肌よわよわ人間です。",
  pekiDescription2:
    "なので普段どんな化粧品でも試せる健康な肌の持ち主は大変幸せ者だと思います。",
  pekiDescription3:
    "選び方が分からないなど迷った時には私の経験が道標になれたら幸いです。",
  pekiDescription4: "【呼ばれたい名前】",
  pekiDescription5: "ペキ",
  pekiDescription6: "【肌質】",
  pekiDescription7: "乾燥や炎症を起こしやすい敏感肌",
  pekiDescription8: "【ライフスタイル】",
  pekiDescription9: "帰宅時間の遅いオフィス勤務の社会人",
  pekiDescription10: "【どんな目線で製品紹介したいか】",
  pekiDescription11:
    "乾燥肌で悩んでて肌が弱くて普段思うように化粧が出来ない人に向けて私はこうしてますなど体験談を含めた紹介ができたらなと",

  /* ----- hamano ---- */
  hamanoTitle: "日本皮肤科学会认定 皮肤科专业医生",
  hamanoName: "濱野英明",
  hamanoHistory1: "略歴",
  hamanoHistory2: "聖マリアンナ医科大学卒業",
  hamanoHistory3: "川崎市立川崎病院(神奈川県)にて内科研修",
  hamanoHistory4: "慶應義塾大学病院皮膚科入局、勤務",
  hamanoHistory5: "稲城市立病院(東京都）皮膚科勤務",
  hamanoHistory6: "済生会横浜市南部病院(神奈川県）皮膚科勤務",
  hamanoHistory7: "平成22年11月 テティス横濱美容皮膚科開設",
  hamanoAffiliated1: "所属学会",
  hamanoAffiliated2: "日本皮膚科学会（皮膚科専門医取得）",
  hamanoAffiliated3: "日本美容皮膚科学会",
  hamanoAffiliated4: "日本東洋医学会",
  hamanoAffiliated5: "日本レーザー医学会",
  hamanoAffiliated6: "日本护肤协会、皮肤科顾问",
  hamanoAffiliated7: "2015ミス・ユニバース ビューティーキャンプ講師",
  hamanoAffiliated8:
    "2018ミススプラインターナショナル神奈川、ビューティーキャンプ講師、審査員",
  hamanoAffiliated9: "ヒアルロン酸、ボトックス注射指導医",

  /* --------------------------- /*
  /* ---------- About ---------- */
  /* --------------------------- */
  aboutTitle1: "和接待员一起做的",
  aboutTitle2: "只有你的美丽",
  aboutIntro1: "在了解自己的肌肤类型的基础上，",
  aboutIntro2: "选择适合自己肌",
  aboutIntro3: "肤的商品",
  aboutIntro4: "护肤方法是很重要的",
  aboutIntro5: "- 美国皮肤病学会 -",

  aboutChangeTopics: "然而",
  aboutQuestion1: "你知道你的",
  aboutQuestion2: "皮肤类型吗？",
  aboutQuestion3: "可以自己选择",
  aboutQuestion4: "适合现在自己皮肤的商品吗",
  aboutQuestion5: "你知道合适的",
  aboutQuestion6: "护肤方法吗？",
  aboutContinueTopics: "此外",
  // aboutQuestion7: "季節",
  // aboutComma: ", ",
  // aboutQuestion8: "スキンケア",
  // aboutQuestion9: "生活習慣",
  // aboutQuestion10: "などにより",
  // aboutQuestion11: "肌は常に",
  // aboutQuestion12: "変化",
  // aboutQuestion13: "します。",
  aboutCatchCopy1: "是",
  aboutCatchCopy2: "你的病历",
  aboutCanDoTitle1: "中",
  aboutCanDoTitle2: "能做的事",
  aboutCanDoDesc1: "肤质检查",
  aboutCanDoDesc2:
    "通过回答问题，可以检查你现在的皮肤状况。By answering the questions, you can check your current skin condition.",
  aboutCanDoDesc3: "护肤建议",
  aboutCanDoDesc4: "皮肤保养专业的接待员建议适合你的皮肤的护肤品。",
  aboutCanDoDesc5: "化妆提案",
  aboutCanDoDesc6: "专业化妆师会提出适合你的化妆项目和化妆方法。",
  aboutCanDoDesc7: "成分判定",
  aboutCanDoDesc8: "通过记录不适合你皮肤的物品，来判定不适合你皮肤的成分。",
  aboutMykarteDesc1: "我的病历",
  aboutMykarteDesc2: "你可以记录所有",
  aboutMykarteDesc3: "关于你皮肤的信息。",
  aboutMykarteDesc4: "你的肤质",
  aboutMykarteDesc5: "适合肌肤的护肤品",
  aboutMykarteDesc6: "适合你的",
  aboutMykarteDesc7: "化妆方法和商品",
  aboutMykarteDesc8: "配合成分・不合适的成分",
  aboutMykarteDesc9: "记录现在的皮肤状态和",
  aboutMykarteDesc10: "化妆品和皮肤的相合，",
  aboutMykarteDesc11: "制作只属于",
  aboutMykarteDesc12: "你的病历吧。",
  aboutFirstOfAll1: "入门",
  aboutFirstOfAll2: "肤质检查",
  aboutFirstOfAll3: "护肤建议",
  aboutFirstOfAll4: "化妆提案",
  aboutFirstOfAll5: "成分判定",
  // ブランク2つは、デザインのために入れています。
  // aboutOurPromise1: "OUR  PROMISE",
  // aboutOurPromise2: "メーカー・ブランド",
  // aboutOurPromise3: "から",
  // aboutOurPromise4: "広告費",
  // aboutOurPromise5: "をいただかない",
  aboutOurPromiseDesc1: "收到广告费的话，从平坦的视点公正地选定项目，",
  aboutOurPromiseDesc2: "无论如何都做不到，推荐项目的可信度会动摇。",
  aboutOurPromiseDesc3: "YUNI COSME不收取制造商、品牌的广告费，",
  aboutOurPromiseDesc4: "只刊登真正好的项目、真心推荐的项目。",
  aboutStartSkinCheck1: "开始",
  aboutStartSkinCheck2: "检查皮肤",
};

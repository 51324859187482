import { createI18n } from "vue-i18n";
import messages from "./messages";

// main.js から、languageStore の値を取ってくることで、同期させる
export function initializeI18n(app, lang) {
  const i18n = createI18n({
    // ja, en, zh
    locale: lang,
    // 特殊な設定だった場合などの、デフォルトの対応
    fallbackLocale: "ja",
    legacy: false,
    messages: messages,
  });
  app.use(i18n);

  return i18n;
}

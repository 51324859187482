export default {
  /* ############################################ */
  /* ################## Common ################## */
  /* ############################################ */
  /* --- larger category ---- */
  categorySkinCheck: "skin check",
  categorySkincare: "skin care",
  categoryMakeup: "makeup",

  /* --- smaller category --- */
  categorySkinLotion: "skin lotion",
  categoryMilkyLotionCream: "milky lotion, cream",
  categoryAllInOne: "all in one",

  /* --- ingredient --- */
  ingredientCeramide: "ceramide",
  ingredientVitaminC: "vitamin c",
  ingredientRetinol: "retinol",
  ingredientNiacinamide: "niacinamide",
  ingredientTranexamicAcid: "tranexamic acid",

  /* --- problems --- */
  problemPores: "pores",
  problemRoughSkin: "rough skin",
  problemOilySkin: "oily skin",

  /* --- header --- */
  askConcierges: "ask concierges",
  mykarte: "my karte",

  /* ############################################ */
  /* ################ components ################ */
  /* ############################################ */
  /* -------------------- */
  /* ----- Flow Dir ----- */
  /* -------------------- */
  /* ------- question ------ */
  questionBackButton: "return",

  /* -- concierge choice  -- */
  conciergeChoiceQuestion: "Which concierge will you take suggestions from?",
  conciergeChoiceDesc:
    "Choose the concierge you would like to consult. By answering the questions, you can find out which cosmetics the concierge recommends.",
  conciergeSeeProfile: "see profile",
  conciergeSeeProfileDetails: "more information about the profile.",
  conciergeSelect: "select this concierge",

  /* ---- Face Chin ---- */
  faceChinQuestion: "What shape is your chin?",
  faceChinAnswer1: "sticking out",
  faceChinAnswer2: "normal",
  faceChinAnswer3: "jawless",

  /* ---- Face Chiseled ---- */
  faceChiseledQuestion: "Is it deeply chiseled?",
  faceChiseledAnswer1: "finely chiseled",
  faceChiseledAnswer2: "poorly chiseled",

  /* ---- Face Forehead ---- */
  faceForeheadQuestion: "What shape is your forehead?",
  faceForeheadAnswer1: "sticking out",
  faceForeheadAnswer2: "normal",
  faceForeheadAnswer3: "flat forehead",

  /* ---- Face Parts Placement ---- */
  facePartsPlacementQuestion: "Which is closer to your face?",

  /* ---- Face Shape ---- */
  faceShapeQuestion: "choose your face shape",
  faceShapeAnswer1: "oval shape",
  faceShapeAnswer2: "round shape",
  faceShapeAnswer3: "oblong shape",
  faceShapeAnswer4: "diamond shape",
  faceShapeAnswer5: "triangular shape",
  faceShapeAnswer6: "square shape",

  /* ---- Skin Condition ---- */
  skinConditionQuestion: "Please tell me your skin condition",
  skinConditionAnswer1: "healthy skin",
  skinConditionAnswer2: "Color unevenness*",
  skinConditionAnswer3: "Fine wrinkles",
  skinConditionAnswer4: "pores",
  skinConditionFootnote:
    "*These include 'dark spots, freckles, and dark circles.'",

  /* ---- Skin Check Confirm ---- */
  skinCheckConfirmQuestion:
    "Your most recent diagnosis showed the following for your skin. May we make a suggestion here?",
  skinCheckConfirmAnswer1: "yes",
  skinCheckConfirmAnswer2: "skin check again",

  /* -------------------------- */
  /* ---- other components ---- */
  /* -------------------------- */
  /* ---- result filter ---- */
  resultFilterPrice: "price",
  resultFilterCategoryDetails: "category details",
  resultFilterNeedsIngredient: "ingredient you need",
  resultFilterProblemsBeSolved: "skin problems to be solved",

  /* ---- price slider ---- */
  priceSliderTitle: "price",

  /* ---- item details ---- */
  /* ---- makeup tools ---- */
  itemsLowestPrice: "the lowest price",
  itemsUpdate: "update",
  itemsAttention: "* please note that the prices shown may not be accurate.",
  itemsOfficialUrl: "▼ official website",
  itemsConciergeComment: "- concierge comments -",
  itemsShowMore: "▼ show more",
  itemsClose: "▲ close",
  itemsOtherComments: "other concierges are also recommended.",
  itemDetails: "item details",
  itemCategory: "category",
  itemBrand: "brand",
  itemName: "item name",
  itemMakeupTools: "Makeup tools used",
  /* ----- item list ----- */
  itemRetailPrice: "retail price",

  /* ------------------------------- */
  /* ---- Kazunosuke components ---- */
  /* ------------------------------- */
  /* ---- kazunosuke item details ---- */
  kazunosukeItemExplanation: "item description",
  kazunosukeItemBlog: "Read the article on our blog...",
  kazunosukeItemBlogHere: "click here",

  /* ---- kazunosuke item list ---- */
  kazunosukeItemResultTitle: "There are no products matching these criteria.",
  kazunosukeItemsShowMore: "Show more",

  /* ---- kazunosuke result ---- */
  /* ----- skincare result ----- */
  resultToMykarte1: "You can record all information",
  resultToMykarte2: "about your skin.",

  /* ############################################ */
  /* ################### Views ################## */
  /* ############################################ */
  /* --- common --- */
  startSkinCheckButton: "start skin check",

  /* ------------------------------- */
  /* ------------ Flows ------------ */
  /* ------------------------------- */
  /* --- Flow Skin check --- */
  flowSkinCheckSubTitle: "Supervised by cosmetic dermatologist",
  flowSkinCheckDesc:
    "We will analyze your skin condition by answering questions about your current skin condition and concerns. We will help you understand your skin condition and find the right cosmetics for your skin.",
  flowSkinCheckSupervisor: "- supervision -",
  hamanoAffiliatedEx: "Director, Thetis Yokohama Cosmetic Dermatology",

  /* --- Flow Item suggestions --- */
  flowItemSuggestionsSubTitle: "Find the right cosmetics for your skin",
  flowItemSuggestionsDesc1:
    "Based on the results of the skin check, you can choose either skincare or makeup and receive suggestions from the concierge.",
  flowItemSuggestionsDesc2:
    "We will help you find the right cosmetics for your skin by answering questions about your current skin condition, your concerns, and your product price range.",
  flowItemSuggestionsNoResultDesk1: "There are no skin check results yet.",
  flowItemSuggestionsNoResultDesk2:
    "In order to find the right skincare/cosmetics for your skin, it is important to know your skin first.",
  flowItemSuggestionsNoResultDesk3: "First, let's do a skin check.",

  /* ------------------------------- */
  /* ----------- Results ----------- */
  /* ------------------------------- */
  /* --- Result skin check --- */
  resultSkinCheckToSkincareSuggestions: "skin care suggestions",
  resultSkinCheckToMakeupSuggestions: "makeup suggestions",
  resultSkinCheckToIngredientCheck: "ingredient check",

  /* --- Result skin care --- */
  resultSkinCareGetMainConciergeTitle: "Recommended for you.",
  resultSkinCareNoMainConciergeDesc:
    "There are no products recommended by the selected concierge for your consultation. We will suggest products recommended by other concierges.",
  resultSkinCareNoItemsDesc1: "We are very sorry.",
  resultSkinCareNoItemsDesc2:
    "We did not find any recommended products that meet your requirements.",
  resultSkinCareNoItemsDesc3:
    "Please change the conditions and consult the concierge again.",
  resultSkinCareAskConciergeAgain:
    "concierges will suggest recommended products again.",

  /* --- Result makeup --- */
  resultMakeupTitle:
    "We will provide you with a total recommendation of makeup products for you!",
  resultMakeupNoItemsDesc1:
    "For the condition you have answered, there are no concierge recommended products in this category.",

  /* ------------------------------- */
  /* ----------- MyKarte ----------- */
  /* ------------------------------- */
  // MyKarte Areas
  /* --- MyKarte skin check --- */
  mykarteSkinCheckNoResultDesc1: "You have not yet undergone a skin check.",
  mykarteSkinCheckNoResultDesc2:
    "First, it is important to know about your skin.",
  mykarteSkinCheckNoResultDesc3: "Take a skin check.",
  mykarteSkinCheckDesc1: "Human skin changes seasonally.",
  mykarteSkinCheckDesc2: "Would you like to have a skin check?",

  /* --- MyKarte skin care message --- */
  /* --- MyKarte Kazunosuke skin care message --- */
  mykarteSkinCareToItemSuggestions: "Ask for our concierges",

  /* --- MyKarte makeup --- */
  mykarteMakeupNoResultDesc1:
    "We have not yet received any consultation on makeup products.",
  mykarteMakeupNoResultDesc2: "Please ask our concierge.",

  /* --- MyKarte main --- */
  mykarteMainDesc:
    "Here you can check your skin data and the items suggested by our concierge.",

  /* --------------------------- /*
  /* ------- Other pages ------- */
  /* --------------------------- */
  /* --- privacy policy --- */
  privacyPolicyTitle: "Privacy Policy",

  /* --- terms of use --- */
  termsTitle: "Terms of Use",

  /* ----- Contact ---- */
  contactTitle: "CONTACT",
  sentence1: "For inquiries to YUNI COSME, please contact us here.",
  sentence2:
    "Our representative will contact you within 3 business days. Please note that we may not be able to reply to you depending on the content of your inquiry. Please understand that we may not be able to reply depending on the content.",
  caution:
    "*Please note that individual consultations with the concierge are not available.",
  contactButton: "CONTACT US",

  /* ------ Trend ------ */
  trendTitle: "trend",
  guideToItemSuggestion: "Let's look for cosmetics that suit your skin!",
  guideToItemSuggestionButton:
    "Receive product recommendations from the concierge",

  /* --------------------------- /*
  /* -------- Concierges ------- */
  /* --------------------------- */
  conciergeTitle: "concierges",

  /* ------ common ----- */
  profile: "profile",
  comment: "comment",

  /* --- kazunosuke --- */
  kazunosukeTitle: "Chemistry teacher teaching beauty",
  kazunosukeName: "kazunosuke",
  kazunosukeDescription1:
    "アトピーで肌荒れに悩んだ経験から美容や化粧品に関心を持ち、大学院等で化粧品や界面活性剤について専門で研究。",
  kazunosukeDescription2:
    "2013年頃より各種SNSにて化粧品の成分解析や様々な美容情報の発信を開始。",
  kazunosukeDescription3:
    "アメブロ月間最高500万PV、Twitterフォロワー21.7万人、YouTube登録者33.5万人。",
  kazunosukeDescription4:
    "「美容化学者」として美容関連著書を10冊以上執筆し、敏感肌向けオリジナル化粧品「CeraLabo」の製品設計＆企画開発も手掛ける。",
  kazunosukeDescription5:
    "最終学歴：横浜国立大学院卒・環境学修士　※博士課程後期中退",
  kazunosukeDescription6:
    "化粧品企画開発・広告指導顧問　株式会社セララボ　代表取締役",

  /* ------ manami ---- */
  manamiTitle: "トータルビジュアルプロデューサー",
  manamiName: "MANAMI",
  manamiShort1:
    "海外でのモデル経験を活かし、日本に帰国後はヘアメイク、ファッション、歩き方など「見た目に関するすべてのプロデュース」を手掛ける。",
  manamiShort2:
    "なりたいもの目指すものと本人が持つ素質を絶妙に見極めブランディングすることを得意とする。",
  manamiShort3: "顧客は芸能人、政治家、専門家、ミスコンテストの出場者など。",
  manamiDescription1:
    "主な著書は「メイクの超基本テクニック（マイナビ）」「一重奥二重さんの大人メイク（タツミムック）」など全６冊。",
  manamiDescription2:
    "40歳を目前に美容業界や芸能界の人々が法律知識を持つ必要性を感じ、プレーヤー経験者の弁護士を目指して大学受験し合格。中央大学法学部法律学科４年に在籍中。",
  manamiComment1:
    "はじめましての方も、よく知って下さる方もご覧いただきありがとうございます。",
  manamiComment2:
    "私はプロとして「使ってみて納得した」「自腹でも買いたい」「モデルさんや大切な人たちに使ってもらいたい」製品だけを選んでメイクボックスに入れています。",
  manamiComment3:
    "私自身は超がつく乾燥肌、そして４０歳を過ぎた年齢肌、少しの敏感肌ととても面倒な肌質の持ち主ですが、だからこそお肌悩みがある方々のための化粧品選びはとても慎重に行っているつもりです！",
  manamiComment4:
    "毎日が仕事と大学と勉強で忙しい日々を送っており、とっても根が面倒くさがりなので時短コスメやオールインワンコスメが大好きですので、ズボラできるコスメがお好きな方は是非是非私を参考にしていただければと思います！",
  manamiComment5:
    "働いているかどうか、子供がいるかどうか関係なくほとんどの方が基本的に忙しい日々を送っていると思います。時間がある時のためのスペシャルケアももちろん披露していきますが、毎日ケアは頑張りすぎなくて良いんです。自分にフィットするアイテム選びをしていただければ手間が減らせますからね。軽率にきれいになってしまいましょう！笑",

  /* ------- eri ------ */
  eriTitle: "Professional Makeup Artist",
  eriName: "Eri",
  eriShort1: "カリフォルニア州で美容師免許を取得後、美容院に勤務。",
  eriShort2:
    "日本に帰国後ヘアメイクに転身し、現在はサロン経営の傍ら広告やファッション業界でヘアメイクを担当",
  eriDescription1:
    "カリフォルニアで10代後半を過ごし、すっぴんでパジャマの状態でジャージしか着ていないライフスタイルだった、ずぼらな私がかれこれ10年以上美容業界で仕事をし、細々とサロンも営業させて頂いております。",
  eriDescription2:
    "コスメ紹介を通じていかに楽に、簡単に、自分に満足できる顔、髪型を手に入れられるかをお伝えしていきたいと思っております。",
  eriDescription3:
    "日本の女性は、みなさん器用でメイクも上手く、肌も綺麗と世界的に高い評価を受けていると思います。",
  eriDescription4:
    "その事を誇りに、よりハッピーな、個々のスタイルを見つけて楽しい生活をおくれるお手伝い出来る事を楽しみにしてます。",
  eriComment1: "【肌質】",
  eriComment2: "混合肌。",
  eriComment3:
    "目元、口元は乾燥しやすく、鼻筋、おでこのテカりはすぐ出て来てしまう、厄介な肌質で、叔父、兄が重度のアトピーで、自分自身も免疫力が弱まるとアトピーぽい症状が出てしまいます。",
  eriComment4: "【ライフスタイル】",
  eriComment5:
    "撮影の現場が不定期かつ、時間も不規則なので、肌荒れや体調は崩しやすく、お弁当や外食も多いので、休日は犬と一緒にのんびり過ごしたり家で自炊して食べる事が多いです。",
  eriComment6: "【どんな目線で製品紹介したいか】",
  eriComment7:
    "広告（主にCM)やファッションのLook撮影やカタログの撮影で実際使ってモデルさんに喜ばれるコスメや、周りの美容業界の方にオススメされた製品など、コスパや成分だけではないトレンドや女子力を刺激するコスメのご紹介をしていきたいです。",
  eriComment8:
    "流行っている物はもちろん、実際にプロの方が多く使っている物や長年人気なコスメの紹介も出来ればと思っております。",

  /* --- Ayako --- */
  ayakoName: "Ayako",
  ayakoShort1:
    "スキンケア商品を主とするコスメブランドにて、営業と商品企画を経験。",
  ayakoShort2:
    "その後、大手エステティック企業や香水メーカーでの事業運営を通じ、今までに100以上のアイテムを世に送り出してきた。",
  ayakoShort3:
    "2019年4月に独立後、10年に渡る美容業界の経験を活かし、新規コスメブランドのプロデュースやサポートを行っている。",
  ayakoDescription1:
    "ポーラ・オルビスホールディングス傘下メーカーの化粧品の営業・企画開発職を経て、TBCグループ株式会社に入社。",
  ayakoDescription2:
    "リテール事業の立ち上げから美容・健康食品領域の企画開発、大手コンビニのPB製品などのコラボレーション事業に従事。",
  ayakoDescription3: "その後フレグランスメーカーを経て2019年より独立。",
  ayakoDescription4:
    "これまでスキンケア中心に100を超える製品企画に携わりオンライン・オフライン流通に展開。",
  ayakoDescription5:
    "現在はコスメ開発コンサルタントとして、美容関連商品の企画開発から事業立ち上げ、人材育成まで美容業界に関わる幅広い業務をサポートしています。",
  ayakoComment1: "ご訪問いただきありがとうございます。",
  ayakoComment2:
    "加齢、食事、睡眠、生理周期やストレス、環境変化など肌はさまざまな影響を受けて日々変化します。",
  ayakoComment3:
    "そのため、ある程度の効果を感じながらも心地よく、変化に左右されずに安心して使えることが化粧品の役割として大切なことだと思っています。",
  ayakoComment4:
    "これまで10年間、自身の肌だけではなく数百回行ってきた製品開発モニターでの知見などをもとにおすすめしたいアイテムをご紹介したいと思っています。",

  /* --- Maya --- */
  mayaName: "Maya",
  mayaShort1:
    "ミスインターナショナル、ミスユニバース、ミススプラナショナル、ミス日本etc...など日本大会出場",
  mayaShort2: "ワンホンアジアスーパーチャレンジ 世界大会5位",
  mayaShort3: "ミススーパータレント インザワールド 世界大会出場",
  mayaShort4: "ミスアジア日本代表 世界大会出場…etc",
  mayaShort5:
    "ミスコン出場の傍らエステティシャン、美容部員として日本・韓国で働く",
  mayaShort6: "モデル、キャンペーンガール、ラジオなど多数",
  mayaDescription1: "こんにちは！",
  mayaDescription2: "Mayaです。",
  mayaDescription3:
    "私は幼稚園の頃から化粧品に興味を持つようになり、高校生の頃には成分まで調べるようになりました。",
  mayaDescription4:
    "顔の右側は商品A、左側は商品Bというように、自分で使ってみて効果を検証するのが趣味で、業務用の肌チェックをする機械まで家にあったりします（もはやマニア・・・笑）",
  mayaDescription5:
    "おそらく、ドラッグストアやデパコスの商品はだいたい制覇しました笑",
  mayaDescription6:
    "世の中の商品をみているとCMとかで人気芸能人を起用して人気のコスメでも、使ったその瞬間はいいけど・・・」というような、長い目でみたら肌への負担が大きい商品も結構あります。",
  mayaDescription7:
    "使った瞬間はよく見えるので、成分の知識を持っていないとこういう商品の良し悪しは判断ができません。",
  mayaDescription8:
    "私のマニアぶりを知る友達からオススメのコスメを聞かれることが多かったので、少しでも私の知識や趣味が皆さんの役に立つならば、、、と思って作ったのがこのサービスです。",
  mayaDescription9: "私の美容のコンセプトは",
  mayaDescription10:
    "「全てのメイクも美しい肌があってこそ。健康的な肌を保って、ずっと長くナチュラルに美しく」です。",
  mayaDescription11:
    "どの記事・レビューもきちんとした知識に基づいて、広告費などをもらうことなく本音を書いています。",
  mayaDescription12:
    "皆さんのコスメ選びや、健康的な肌を保つのに少しでもお役に立てればとっても嬉しいです！",

  /* --- Nico --- */
  nicoName: "Nico",
  nicoShort1:
    "外資系化粧品メーカーでの美容部員、メイクアップアーティスト、ブランドトレーナー、コスメ専門ECサイトバイヤー等25年以上に渡る化粧品業界での経験と、30歳すぎに経験した自身の肌荒れを皮切りに、不妊治療経験、更年期の劇的なカラダの変化など様々な実体験から「肌とカラダとココロを活かすナチュラルなトータルセルフケア」を実現するオーガニック・ナチュラルコスメをはじめ心地よく暮らすための衣食住の提案を得意とする。",
  nicoDescription1: "セルフケアサロン「やさしい暮らしと肌の研究所」代表",
  nicoComment1: "こちらのページにご訪問いただきありがとうございます。",
  nicoComment2:
    "長く化粧品業界を眺めてきた私が行き着いたのは、「スキンケアは人生をうるおわせる」ということ。",
  nicoComment3:
    "そして、そのスキンケアになくてはならない「コスメ」は、人の生き方を左右するほど私たちにとって大切な人生のパートナーです。",
  nicoComment4:
    "とかくカラダにダメージを与えるコトやモノが多くなった今の時代には、ほんの数分のスキンケアという行為がもっとも自分を近くに感じ癒すことのできる愛すべき時間だから、毎日たくさん肌と会話してほしいなと思います。",
  nicoComment5:
    "肌はあなたのカラダとココロの小さな変化をつぶさに捉え、いつでもたくさんのことを教えてくれます。",
  nicoComment6:
    "そんな肌に日々感謝しながら、カラダごと喜ぶあなただけの運命のコスメに出会ってくださいね。",

  /* --- peki --- */
  pekiName: "ペキ",
  pekiShort1:
    "21歳で美容師に就いてから、あっという間にアラフォーに近づいておりますが、ヘアメイク、エステを通じ美容がずっと身近にあります。",
  pekiShort1:
    "今はメイクの現場からは離れて社会人をやっておりますが、美容好きが高じて縁あって今回参加させて頂く運びとなりました。",
  pekiDescription1:
    "自分自身も炎症により化粧も出来ないひどい肌荒れに悩まされていた時期もありながら、令和をたくましく生きるお肌よわよわ人間です。",
  pekiDescription2:
    "なので普段どんな化粧品でも試せる健康な肌の持ち主は大変幸せ者だと思います。",
  pekiDescription3:
    "選び方が分からないなど迷った時には私の経験が道標になれたら幸いです。",
  pekiDescription4: "【呼ばれたい名前】",
  pekiDescription5: "ペキ",
  pekiDescription6: "【肌質】",
  pekiDescription7: "乾燥や炎症を起こしやすい敏感肌",
  pekiDescription8: "【ライフスタイル】",
  pekiDescription9: "帰宅時間の遅いオフィス勤務の社会人",
  pekiDescription10: "【どんな目線で製品紹介したいか】",
  pekiDescription11:
    "乾燥肌で悩んでて肌が弱くて普段思うように化粧が出来ない人に向けて私はこうしてますなど体験談を含めた紹介ができたらなと",

  /* ----- hamano ---- */
  hamanoTitle:
    "Board Certified Dermatologist, Japanese Dermatological Association",
  hamanoName: "濱野英明",
  hamanoHistory1: "略歴",
  hamanoHistory2: "聖マリアンナ医科大学卒業",
  hamanoHistory3: "川崎市立川崎病院(神奈川県)にて内科研修",
  hamanoHistory4: "慶應義塾大学病院皮膚科入局、勤務",
  hamanoHistory5: "稲城市立病院(東京都）皮膚科勤務",
  hamanoHistory6: "済生会横浜市南部病院(神奈川県）皮膚科勤務",
  hamanoHistory7: "平成22年11月 テティス横濱美容皮膚科開設",
  hamanoAffiliated1: "所属学会",
  hamanoAffiliated2: "日本皮膚科学会（皮膚科専門医取得）",
  hamanoAffiliated3: "日本美容皮膚科学会",
  hamanoAffiliated4: "日本東洋医学会",
  hamanoAffiliated5: "日本レーザー医学会",
  hamanoAffiliated6: "Japan Skin Care Association, Dermatology Advisor",
  hamanoAffiliated7: "2015ミス・ユニバース ビューティーキャンプ講師",
  hamanoAffiliated8:
    "2018ミススプラインターナショナル神奈川、ビューティーキャンプ講師、審査員",
  hamanoAffiliated9: "ヒアルロン酸、ボトックス注射指導医",

  /* --------------------------- /*
  /* ---------- About ---------- */
  /* --------------------------- */
  aboutTitle1: "Create your own beauty",
  aboutTitle2: "with our concierge",
  aboutIntro1: "It is important to know",
  aboutIntro2: "your skin type and choose products",
  aboutIntro3: "and skin care methods",
  aboutIntro4: "that suit your skin",
  aboutIntro5: "- American Academy of Dermatology -",

  aboutChangeTopics: "However",
  aboutQuestion1: "Do you know",
  aboutQuestion2: "your skin type?",
  aboutQuestion3: "Can you choose the right product",
  aboutQuestion4: "for your current skin on your own?",
  aboutQuestion5: "Do you know how to",
  aboutQuestion6: "properly care for your skin?",
  aboutContinueTopics: "furthermore",
  // aboutQuestion7: "季節",
  // aboutComma: ", ",
  // aboutQuestion8: "スキンケア",
  // aboutQuestion9: "生活習慣",
  // aboutQuestion10: "などにより",
  // aboutQuestion11: "肌は常に",
  // aboutQuestion12: "変化",
  // aboutQuestion13: "します。",
  aboutCatchCopy1: " is",
  aboutCatchCopy2: "your Karte",
  aboutCanDoTitle1: " can",
  aboutCanDoTitle2: " do",
  aboutCanDoDesc1: "skin check",
  aboutCanDoDesc2:
    "By answering the questions, you can check your current skin condition.",
  aboutCanDoDesc3: "skin care suggestion",
  aboutCanDoDesc4:
    "Our concierge, a skincare specialist, will suggest skincare items that suit your skin.",
  aboutCanDoDesc5: "makeup suggestion",
  aboutCanDoDesc6:
    "Our professional makeup artists will suggest makeup items and methods that suit you.",
  aboutCanDoDesc7: "ingredient check",
  aboutCanDoDesc8:
    "By recording items that do not agree with your skin, we determine which ingredients do not agree with your skin.",
  aboutMykarteDesc1: "Mykarte",
  aboutMykarteDesc2: "You can record",
  aboutMykarteDesc3: "all information about your skin.",
  aboutMykarteDesc4: "Your skin type",
  aboutMykarteDesc5: "Skin care products that suit your skin",
  aboutMykarteDesc6: "Makeup methods and products",
  aboutMykarteDesc7: "that suit you",
  aboutMykarteDesc8: "Components that fit and do not fit",
  aboutMykarteDesc9: "Create your own personal medical record",
  aboutMykarteDesc10: "by recording your current skin condition",
  aboutMykarteDesc11: "and the compatibility of your skin",
  aboutMykarteDesc12: "with cosmetics.",
  aboutFirstOfAll1: "First",
  aboutFirstOfAll2: "skin check",
  aboutFirstOfAll3: "skin care suggestion",
  aboutFirstOfAll4: "makeup suggestion",
  aboutFirstOfAll5: "ingredient check",
  // ブランク2つは、デザインのために入れています。
  // aboutOurPromise1: "OUR  PROMISE",
  // aboutOurPromise2: "メーカー・ブランド",
  // aboutOurPromise3: "から",
  // aboutOurPromise4: "広告費",
  // aboutOurPromise5: "をいただかない",
  aboutOurPromiseDesc1:
    "We believe that if we receive advertising fees, we will inevitably lose the ability to select items fairly from a flat perspective,",
  aboutOurPromiseDesc2:
    "and the credibility of our recommendations will be shaken.",
  aboutOurPromiseDesc3:
    "YUNI COSME does not receive any advertising fees from manufacturers or brands,",
  aboutOurPromiseDesc4:
    "and will only list items that are truly good and that we can recommend wholeheartedly.",
  aboutStartSkinCheck1: "Start",
  aboutStartSkinCheck2: "Skin Check",
};

<script>
import { useLanguageStore } from '@/stores/language'

export default {
  name: 'GlobalFooter',
  setup() {
    const year = new Date().getUTCFullYear()

    // Change state language
    const languageStore = useLanguageStore()
    function switchLang(lang) {
      languageStore.lang = lang
    }

    return {
      languageStore,
      switchLang,
      year,
    }
  }
};
</script>

<template>
  <footer>
    <div class="footer_contents">
      <a
        class="footer_logo"
        href="#"
      >
        <img src="@/assets/logos/logo_wide.png">
      </a>
      <a
        class="instagram_btn"
        href="https://www.instagram.com/yuni_cosme"
      >
        <img src="@/assets/logos/instagram_logo.svg">
      </a>
      <!-- 多言語化対応 -->
      <div class="locale-changer">
        <button
          type="button"
          :class='{"font-bold": languageStore.lang === "ja"}'
          @click="switchLang('ja')"
        >
          JA
        </button>
        <button
          type="button"
          :class='{"font-bold": languageStore.lang === "en"}'
          @click="switchLang('en')"
        >
          EN
        </button>
        <button
          type="button"
          :class='{"font-bold": languageStore.lang === "zh"}'
          @click="switchLang('zh')"
        >
          ZH
        </button>
      </div>
      <!-- サブページへのリンク設定 -->
      <div class="footer_links">
        <div class="footer_links_firstLine">
          <router-link
            to="/about"
          >
            ABOUT
          </router-link>
          <a href="https://justfit.xyz/">
            COMPANY
          </a>
        </div>
        <div class="footer_links_secondLine">
          <router-link
            to="/contact"
          >
            CONTACT
          </router-link>
          <router-link
            to="/terms"
          >
            TERMS
          </router-link>
          <router-link
            to="/privacy-policy"
          >
            PRIVACY&nbsp;POLICY
          </router-link>
        </div>
      </div>
      <p class="footer_copyright">
        ©&nbsp;2017&nbsp;-
        {{ year }}
        &nbsp;JustFit&nbsp;inc,&nbsp;All&nbsp;rights&nbsp;reserved.
      </p>
    </div>
  </footer>
</template>

<style scoped>
footer {
  width: 100%;
  background: #F5EAE7;
  font-weight: bold;
  max-width: 414px;
  margin: 0 auto;
}
.footer_contents {
  padding: 100px 0px;
  text-align: center;
  width: 260px;
  margin: 0 auto;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
img, embed, object, iframe {
  vertical-align: bottom;
}.footer_logo img {
  width: 150px;
}
.locale-changer {
  display: flex;
  width: 60%;
  margin: auto;
}
.locale-changer button {
  width: 100%;
  margin: auto;
  padding: 10px 10px;
}
.instagram_btn {
  display: block;
  text-align: center;
  padding: 30px 0 30px 0;
}
.instagram_btn img {
  width: 36px;
  height: 36px;
}
.footer_links {
  font-size: 10px;
  height: 50px;
}
.footer_links_firstLine {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.footer_links_firstLine a {
  font-size: 14px;
  padding: 0 10px;
}
.footer_links_secondLine {
  display: flex;
  justify-content: center;
}
.footer_links_secondLine a {
  font-size: 14px;
  padding: 0 10px;
}
.footer_copyright {
  padding-top: 50px;
  font-size: 10px;
}

/* lang font setting */
.font-bold {
  font-weight: 700;
  font-size: 24px;
}
</style>

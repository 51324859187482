import { createApp } from "vue";
import App from "./App.vue";
// ルーティング
import router from "./router";
// ステート管理
import { createPinia } from "pinia";
// 多言語化
import { initializeI18n } from "./i18n/index";
import { useLanguageStore } from "./stores/language";
// Google Analytics
import VueGtag from "vue-gtag";

const app = createApp(App);
app.use(router);

// setup Pinia
const pinia = createPinia();
app.use(pinia);

// 言語の設定をここで管理する
const languageStore = useLanguageStore();

// import i18n settings
const i18n = initializeI18n(app, languageStore.lang);

languageStore.$subscribe((_mutation, state) => {
  // i18n.global.locale.value: $t の参照先
  console.info("beforeLanguage", i18n.global.locale.value);
  // state.lang: 実際に変更される値
  // ここが Pinia と i18n のつなぎ込み部分で、かなり大事
  i18n.global.locale.value = state.lang;
  console.info("changedLanguage", i18n.global.locale.value);
});

app.use(
  VueGtag,
  {
    pageTrackerTemplate(to) {
      return {
        page_title: to.name === "Mykarte" ? "Mykarte-SkinTypeDiag" : to.name,
        page_path: to.path,
      };
    },
    config: { id: process.env.VUE_APP_GA_ID },
  },
  router
);

app.mount("#app");

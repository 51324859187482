export default {
  /* ############################################ */
  /* ################## Common ################## */
  /* ############################################ */
  serviceTitle: "YUNI COSME",

  /* --- larger category ---- */
  categorySkinCheck: "肌チェック",
  categorySkincare: "スキンケア",
  categoryMakeup: "メイク",

  /* --- smaller category --- */
  categorySkinLotion: "化粧水",
  categoryMilkyLotionCream: "乳液・クリーム",
  categoryAllInOne: "オールインワン",

  /* --- ingredient --- */
  ingredientCeramide: "セラミド",
  ingredientVitaminC: "ビタミンC",
  ingredientRetinol: "レチノール",
  ingredientNiacinamide: "ナイアシンアミド",
  ingredientTranexamicAcid: "トラネキサム酸",

  /* --- problems --- */
  problemPores: "毛穴",
  problemRoughSkin: "肌あれ",
  problemOilySkin: "脂性肌",

  /* --- header --- */
  askConcierges: "コンシェルジュに相談",
  mykarte: "マイカルテ",

  /* ############################################ */
  /* ################ components ################ */
  /* ############################################ */
  /* -------------------- */
  /* ----- Flow Dir ----- */
  /* -------------------- */
  /* ------- question ------ */
  questionBackButton: "戻る",

  /* -- concierge choice  -- */
  conciergeChoiceQuestion: "どのコンシェルジュに提案を受けますか？",
  conciergeChoiceDesc:
    "あなたが相談してみたいコンシェルジュを選びましょう。質問に回答していくことで、コンシェルジュのオススメする化粧品が分かります。",
  conciergeSeeProfile: "プロフィールを見る",
  conciergeSeeProfileDetails: "プロフィールの詳細はこちら",
  conciergeSelect: "このコンシェルジュを選択",

  /* ---- Face Chin ---- */
  faceChinQuestion: "あなたのあごはどの形ですか？",
  faceChinAnswer1: "あごが出ている",
  faceChinAnswer2: "ふつう",
  faceChinAnswer3: "あごがない",

  /* ---- Face Chiseled ---- */
  faceChiseledQuestion: "彫りは深いですか？",
  faceChiseledAnswer1: "彫りが深い",
  faceChiseledAnswer2: "彫りが浅い",

  /* ---- Face Forehead ---- */
  faceForeheadQuestion: "あなたのおでこはどの形ですか？",
  faceForeheadAnswer1: "おでこが出ている",
  faceForeheadAnswer2: "ふつう",
  faceForeheadAnswer3: "おでこが平ら",

  /* ---- Face Parts Placement ---- */
  facePartsPlacementQuestion: "あなたの顔はどちらが近いですか？",

  /* ---- Face Shape ---- */
  faceShapeQuestion: "あなたの顔の形をお選びください",
  faceShapeAnswer1: "卵型",
  faceShapeAnswer2: "丸型",
  faceShapeAnswer3: "面長",
  faceShapeAnswer4: "ベース型",
  faceShapeAnswer5: "三角",
  faceShapeAnswer6: "四角",

  /* ---- Skin Condition ---- */
  skinConditionQuestion: "肌コンディションを教えてください",
  skinConditionAnswer1: "健康肌",
  skinConditionAnswer2: "色むら*",
  skinConditionAnswer3: "小ジワ",
  skinConditionAnswer4: "毛穴",
  skinConditionFootnote: "*「くすみ、そばかす、くま」なども含まれます。",

  /* ---- Skin Check Confirm ---- */
  skinCheckConfirmQuestion:
    "直近の診断であなたの肌は以下の通りでした。こちらでご提案をしてよろしいでしょうか？",
  skinCheckConfirmAnswer1: "はい",
  skinCheckConfirmAnswer2: "もう一度肌チェックをする",

  /* -------------------------- */
  /* ---- other components ---- */
  /* -------------------------- */
  /* ---- result filter ---- */
  resultFilterPrice: "価格",
  resultFilterCategoryDetails: "詳細カテゴリー",
  resultFilterNeedsIngredient: "特にとりいれたい成分",
  resultFilterProblemsBeSolved: "解決したい肌悩み",

  /* ---- price slider ---- */
  priceSliderTitle: "金額",

  /* ---- item details ---- */
  /* ---- makeup tools ---- */
  itemsLowestPrice: "最安値を見る",
  itemsUpdate: "更新",
  itemsAttention:
    "* 表示されている価格が正確ではない可能性がありますことをご留意ください。",
  itemsOfficialUrl: "▼ 公式ページへ",
  itemsConciergeComment: "- コンシェルジュのコメント -",
  itemsShowMore: "▼ もっと見る",
  itemsClose: "▲ 閉じる",
  itemsOtherComments: "他のコンシェルジュもおすすめしています",
  itemDetails: "商品詳細",
  itemCategory: "カテゴリー",
  itemBrand: "ブランド",
  itemName: "商品名",
  itemMakeupTools: "使用したメイク道具",
  /* ----- item list ----- */
  itemRetailPrice: "小売希望価格",

  /* ------------------------------- */
  /* ---- Kazunosuke components ---- */
  /* ------------------------------- */
  /* ---- kazunosuke item details ---- */
  kazunosukeItemExplanation: "商品説明",
  kazunosukeItemBlog: "ブログでの紹介記事は",
  kazunosukeItemBlogHere: "コチラ",

  /* ---- kazunosuke item list ---- */
  kazunosukeItemResultTitle: "こちらの条件にマッチする商品はありません。",
  kazunosukeItemsShowMore: "もっと見る",

  /* ---- kazunosuke result ---- */
  /* ----- skincare result ----- */
  resultToMykarte1: "あなたの肌に関する全ての情報を",
  resultToMykarte2: "記録することができます。",

  /* ############################################ */
  /* ################### Views ################## */
  /* ############################################ */
  /* --- common --- */
  startSkinCheckButton: "肌チェックを受ける",

  /* ------------------------------- */
  /* ------------ Flows ------------ */
  /* ------------------------------- */
  /* --- Flow Skin check --- */
  flowSkinCheckSubTitle: "美容皮膚科医監修",
  flowSkinCheckDesc:
    "あなたの現在の肌状態やお悩みなど、質問に回答していくことで、肌の状態を分析いたします。自分の肌の状態を理解し、肌にあった化粧品を見つけるお手伝いをいたします。",
  flowSkinCheckSupervisor: "- 監修 -",
  hamanoAffiliatedEx: "テティス横濱美容皮膚科 院長",

  /* --- Flow Item suggestions --- */
  flowItemSuggestionsSubTitle: "あなたの肌にあうコスメが見つかる",
  flowItemSuggestionsDesc1:
    "肌チェックの結果をもとに、スキンケア・メイクのどちらかを選択し、コンシェルジュの提案を受けることができます。",
  flowItemSuggestionsDesc2:
    "あなたの現在の肌状態やお悩み、商品の価格帯などについての質問に回答していくことで、あなたの肌に合った化粧品を見つけるお手伝いをいたします。",
  flowItemSuggestionsNoResultDesk1: "肌チェックの結果がまだありません。",
  flowItemSuggestionsNoResultDesk2:
    "お客さまの肌に合うスキンケア・コスメを探すためにはまず自分の肌を知ることが大切です。",
  flowItemSuggestionsNoResultDesk3: "まずは肌チェックをしてみましょう。",

  /* ------------------------------- */
  /* ----------- Results ----------- */
  /* ------------------------------- */
  /* --- Result skin check --- */
  resultSkinCheckToSkincareSuggestions: "スキンケア提案",
  resultSkinCheckToMakeupSuggestions: "メイク提案",
  resultSkinCheckToIngredientCheck: "成分判定",

  /* --- Result skin care --- */
  resultSkinCareGetMainConciergeTitle: "あなたにオススメの商品はこちらです。",
  resultSkinCareNoMainConciergeDesc:
    "ご相談いただいた内容では、選択されたコンシェルジュからのオススメ商品がありません。他のコンシェルジュのオススメ商品をご提案いたします。",
  resultSkinCareNoItemsDesc1: "大変申し訳ございません。",
  resultSkinCareNoItemsDesc2:
    "お客様の条件にあうオススメ商品がありませんでした。",
  resultSkinCareNoItemsDesc3:
    "条件を変えて再度、コンシェルジュにご相談ください。",
  resultSkinCareAskConciergeAgain:
    "もう一度コンシェルジュからオススメ商品の提案を受ける",

  /* --- Result makeup --- */
  resultMakeupTitle: "あなたにおすすめのメイク商品を、トータルでご提案します！",
  resultMakeupNoItemsDesc1:
    "お答えいただいた条件の場合、このカテゴリーでコンシェルジュおすすめの商品はありません。",

  /* ------------------------------- */
  /* ----------- MyKarte ----------- */
  /* ------------------------------- */
  // MyKarte Areas
  /* --- MyKarte skin check --- */
  mykarteSkinCheckNoResultDesc1: "まだ肌チェックを受けていただいておりません。",
  mykarteSkinCheckNoResultDesc2: "まずは自分の肌について知ることが大切。",
  mykarteSkinCheckNoResultDesc3: "肌チェックを受けてみましょう。",
  mykarteSkinCheckDesc1: "人の肌は季節ごとに変化します。",
  mykarteSkinCheckDesc2: "肌チェックを受けてみませんか？",

  /* --- MyKarte skin care message --- */
  /* --- MyKarte Kazunosuke skin care message --- */
  mykarteSkinCareToItemSuggestions: "コンシェルジュに相談する",

  /* --- MyKarte makeup --- */
  mykarteMakeupNoResultDesc1: "まだメイク商品のご相談を受けておりません。",
  mykarteMakeupNoResultDesc2: "ぜひコンシェルジュにお尋ねください。",

  /* --- MyKarte main --- */
  mykarteMainDesc:
    "こちらではあなたの肌のデータや、コンシェルジュが提案させていただいたアイテムが確認できます。",

  /* --------------------------- /*
  /* ------- Other pages ------- */
  /* --------------------------- */
  /* --- privacy policy --- */
  privacyPolicyTitle: "プライバシーポリシー",

  /* --- terms of use --- */
  termsTitle: "利用規約",

  /* ----- Contact ----- */
  contactTitle: "お問い合わせ",
  sentence1: "YUNI COSMEへのお問い合わせは、こちらからお願いします。",
  sentence2:
    "通常3営業日以内に担当者よりご連絡いたします。内容によって回答できない場合もございます。ご了承ください。",
  caution:
    "※コンシェルジュへの個別相談はお受けできませんので予めご了承ください。",
  contactButton: "お問い合わせはこちら",

  /* ------ Trend ------ */
  trendTitle: "トレンド",
  guideToItemSuggestion: "自分の肌に合う化粧品を探してみましょう",
  guideToItemSuggestionButton: "コンシェルジュからおすすめ商品の提案を受ける",

  /* --------------------------- /*
  /* -------- Concierges ------- */
  /* --------------------------- */
  conciergeTitle: "コンシェルジュ一覧",

  /* ------ common ----- */
  profile: "プロフィール",
  comment: "コメント",

  /* --- kazunosuke --- */
  kazunosukeTitle: "美容を教える化学の先生",
  kazunosukeName: "かずのすけ",
  kazunosukeDescription1:
    "アトピーで肌荒れに悩んだ経験から美容や化粧品に関心を持ち、大学院等で化粧品や界面活性剤について専門で研究。",
  kazunosukeDescription2:
    "2013年頃より各種SNSにて化粧品の成分解析や様々な美容情報の発信を開始。",
  kazunosukeDescription3:
    "アメブロ月間最高500万PV、Twitterフォロワー21.7万人、YouTube登録者33.5万人。",
  kazunosukeDescription4:
    "「美容化学者」として美容関連著書を10冊以上執筆し、敏感肌向けオリジナル化粧品「CeraLabo」の製品設計＆企画開発も手掛ける。",
  kazunosukeDescription5:
    "最終学歴：横浜国立大学院卒・環境学修士　※博士課程後期中退",
  kazunosukeDescription6:
    "化粧品企画開発・広告指導顧問　株式会社セララボ　代表取締役",

  /* ------ manami ---- */
  manamiTitle: "トータルビジュアルプロデューサー",
  manamiName: "MANAMI",
  manamiShort1:
    "海外でのモデル経験を活かし、日本に帰国後はヘアメイク、ファッション、歩き方など「見た目に関するすべてのプロデュース」を手掛ける。",
  manamiShort2:
    "なりたいもの目指すものと本人が持つ素質を絶妙に見極めブランディングすることを得意とする。",
  manamiShort3: "顧客は芸能人、政治家、専門家、ミスコンテストの出場者など。",
  manamiDescription1:
    "主な著書は「メイクの超基本テクニック（マイナビ）」「一重奥二重さんの大人メイク（タツミムック）」など全６冊。",
  manamiDescription2:
    "40歳を目前に美容業界や芸能界の人々が法律知識を持つ必要性を感じ、プレーヤー経験者の弁護士を目指して大学受験し合格。中央大学法学部法律学科４年に在籍中。",
  manamiComment1:
    "はじめましての方も、よく知って下さる方もご覧いただきありがとうございます。",
  manamiComment2:
    "私はプロとして「使ってみて納得した」「自腹でも買いたい」「モデルさんや大切な人たちに使ってもらいたい」製品だけを選んでメイクボックスに入れています。",
  manamiComment3:
    "私自身は超がつく乾燥肌、そして４０歳を過ぎた年齢肌、少しの敏感肌ととても面倒な肌質の持ち主ですが、だからこそお肌悩みがある方々のための化粧品選びはとても慎重に行っているつもりです！",
  manamiComment4:
    "毎日が仕事と大学と勉強で忙しい日々を送っており、とっても根が面倒くさがりなので時短コスメやオールインワンコスメが大好きですので、ズボラできるコスメがお好きな方は是非是非私を参考にしていただければと思います！",
  manamiComment5:
    "働いているかどうか、子供がいるかどうか関係なくほとんどの方が基本的に忙しい日々を送っていると思います。時間がある時のためのスペシャルケアももちろん披露していきますが、毎日ケアは頑張りすぎなくて良いんです。自分にフィットするアイテム選びをしていただければ手間が減らせますからね。軽率にきれいになってしまいましょう！笑",

  /* ------- eri ------ */
  eriTitle: "プロメイクアップアーティスト",
  eriName: "Eri",
  eriShort1: "カリフォルニア州で美容師免許を取得後、美容院に勤務。",
  eriShort2:
    "日本に帰国後ヘアメイクに転身し、現在はサロン経営の傍ら広告やファッション業界でヘアメイクを担当",
  eriDescription1:
    "カリフォルニアで10代後半を過ごし、すっぴんでパジャマの状態でジャージしか着ていないライフスタイルだった、ずぼらな私がかれこれ10年以上美容業界で仕事をし、細々とサロンも営業させて頂いております。",
  eriDescription2:
    "コスメ紹介を通じていかに楽に、簡単に、自分に満足できる顔、髪型を手に入れられるかをお伝えしていきたいと思っております。",
  eriDescription3:
    "日本の女性は、みなさん器用でメイクも上手く、肌も綺麗と世界的に高い評価を受けていると思います。",
  eriDescription4:
    "その事を誇りに、よりハッピーな、個々のスタイルを見つけて楽しい生活をおくれるお手伝い出来る事を楽しみにしてます。",
  eriComment1: "【肌質】",
  eriComment2: "混合肌。",
  eriComment3:
    "目元、口元は乾燥しやすく、鼻筋、おでこのテカりはすぐ出て来てしまう、厄介な肌質で、叔父、兄が重度のアトピーで、自分自身も免疫力が弱まるとアトピーぽい症状が出てしまいます。",
  eriComment4: "【ライフスタイル】",
  eriComment5:
    "撮影の現場が不定期かつ、時間も不規則なので、肌荒れや体調は崩しやすく、お弁当や外食も多いので、休日は犬と一緒にのんびり過ごしたり家で自炊して食べる事が多いです。",
  eriComment6: "【どんな目線で製品紹介したいか】",
  eriComment7:
    "広告（主にCM)やファッションのLook撮影やカタログの撮影で実際使ってモデルさんに喜ばれるコスメや、周りの美容業界の方にオススメされた製品など、コスパや成分だけではないトレンドや女子力を刺激するコスメのご紹介をしていきたいです。",
  eriComment8:
    "流行っている物はもちろん、実際にプロの方が多く使っている物や長年人気なコスメの紹介も出来ればと思っております。",

  /* --- Ayako --- */
  ayakoName: "Ayako",
  ayakoShort1:
    "スキンケア商品を主とするコスメブランドにて、営業と商品企画を経験。",
  ayakoShort2:
    "その後、大手エステティック企業や香水メーカーでの事業運営を通じ、今までに100以上のアイテムを世に送り出してきた。",
  ayakoShort3:
    "2019年4月に独立後、10年に渡る美容業界の経験を活かし、新規コスメブランドのプロデュースやサポートを行っている。",
  ayakoDescription1:
    "ポーラ・オルビスホールディングス傘下メーカーの化粧品の営業・企画開発職を経て、TBCグループ株式会社に入社。",
  ayakoDescription2:
    "リテール事業の立ち上げから美容・健康食品領域の企画開発、大手コンビニのPB製品などのコラボレーション事業に従事。",
  ayakoDescription3: "その後フレグランスメーカーを経て2019年より独立。",
  ayakoDescription4:
    "これまでスキンケア中心に100を超える製品企画に携わりオンライン・オフライン流通に展開。",
  ayakoDescription5:
    "現在はコスメ開発コンサルタントとして、美容関連商品の企画開発から事業立ち上げ、人材育成まで美容業界に関わる幅広い業務をサポートしています。",
  ayakoComment1: "ご訪問いただきありがとうございます。",
  ayakoComment2:
    "加齢、食事、睡眠、生理周期やストレス、環境変化など肌はさまざまな影響を受けて日々変化します。",
  ayakoComment3:
    "そのため、ある程度の効果を感じながらも心地よく、変化に左右されずに安心して使えることが化粧品の役割として大切なことだと思っています。",
  ayakoComment4:
    "これまで10年間、自身の肌だけではなく数百回行ってきた製品開発モニターでの知見などをもとにおすすめしたいアイテムをご紹介したいと思っています。",

  /* --- Maya --- */
  mayaName: "Maya",
  mayaShort1:
    "ミスインターナショナル、ミスユニバース、ミススプラナショナル、ミス日本etc...など日本大会出場",
  mayaShort2: "ワンホンアジアスーパーチャレンジ 世界大会5位",
  mayaShort3: "ミススーパータレント インザワールド 世界大会出場",
  mayaShort4: "ミスアジア日本代表 世界大会出場…etc",
  mayaShort5:
    "ミスコン出場の傍らエステティシャン、美容部員として日本・韓国で働く",
  mayaShort6: "モデル、キャンペーンガール、ラジオなど多数",
  mayaDescription1: "こんにちは！",
  mayaDescription2: "Mayaです。",
  mayaDescription3:
    "私は幼稚園の頃から化粧品に興味を持つようになり、高校生の頃には成分まで調べるようになりました。",
  mayaDescription4:
    "顔の右側は商品A、左側は商品Bというように、自分で使ってみて効果を検証するのが趣味で、業務用の肌チェックをする機械まで家にあったりします（もはやマニア・・・笑）",
  mayaDescription5:
    "おそらく、ドラッグストアやデパコスの商品はだいたい制覇しました笑",
  mayaDescription6:
    "世の中の商品をみているとCMとかで人気芸能人を起用して人気のコスメでも、使ったその瞬間はいいけど・・・」というような、長い目でみたら肌への負担が大きい商品も結構あります。",
  mayaDescription7:
    "使った瞬間はよく見えるので、成分の知識を持っていないとこういう商品の良し悪しは判断ができません。",
  mayaDescription8:
    "私のマニアぶりを知る友達からオススメのコスメを聞かれることが多かったので、少しでも私の知識や趣味が皆さんの役に立つならば、、、と思って作ったのがこのサービスです。",
  mayaDescription9: "私の美容のコンセプトは",
  mayaDescription10:
    "「全てのメイクも美しい肌があってこそ。健康的な肌を保って、ずっと長くナチュラルに美しく」です。",
  mayaDescription11:
    "どの記事・レビューもきちんとした知識に基づいて、広告費などをもらうことなく本音を書いています。",
  mayaDescription12:
    "皆さんのコスメ選びや、健康的な肌を保つのに少しでもお役に立てればとっても嬉しいです！",

  /* --- Nico --- */
  nicoName: "Nico",
  nicoShort1:
    "外資系化粧品メーカーでの美容部員、メイクアップアーティスト、ブランドトレーナー、コスメ専門ECサイトバイヤー等25年以上に渡る化粧品業界での経験と、30歳すぎに経験した自身の肌荒れを皮切りに、不妊治療経験、更年期の劇的なカラダの変化など様々な実体験から「肌とカラダとココロを活かすナチュラルなトータルセルフケア」を実現するオーガニック・ナチュラルコスメをはじめ心地よく暮らすための衣食住の提案を得意とする。",
  nicoDescription1: "セルフケアサロン「やさしい暮らしと肌の研究所」代表",
  nicoComment1: "こちらのページにご訪問いただきありがとうございます。",
  nicoComment2:
    "長く化粧品業界を眺めてきた私が行き着いたのは、「スキンケアは人生をうるおわせる」ということ。",
  nicoComment3:
    "そして、そのスキンケアになくてはならない「コスメ」は、人の生き方を左右するほど私たちにとって大切な人生のパートナーです。",
  nicoComment4:
    "とかくカラダにダメージを与えるコトやモノが多くなった今の時代には、ほんの数分のスキンケアという行為がもっとも自分を近くに感じ癒すことのできる愛すべき時間だから、毎日たくさん肌と会話してほしいなと思います。",
  nicoComment5:
    "肌はあなたのカラダとココロの小さな変化をつぶさに捉え、いつでもたくさんのことを教えてくれます。",
  nicoComment6:
    "そんな肌に日々感謝しながら、カラダごと喜ぶあなただけの運命のコスメに出会ってくださいね。",

  /* --- peki --- */
  pekiName: "ペキ",
  pekiShort1:
    "21歳で美容師に就いてから、あっという間にアラフォーに近づいておりますが、ヘアメイク、エステを通じ美容がずっと身近にあります。",
  pekiShort1:
    "今はメイクの現場からは離れて社会人をやっておりますが、美容好きが高じて縁あって今回参加させて頂く運びとなりました。",
  pekiDescription1:
    "自分自身も炎症により化粧も出来ないひどい肌荒れに悩まされていた時期もありながら、令和をたくましく生きるお肌よわよわ人間です。",
  pekiDescription2:
    "なので普段どんな化粧品でも試せる健康な肌の持ち主は大変幸せ者だと思います。",
  pekiDescription3:
    "選び方が分からないなど迷った時には私の経験が道標になれたら幸いです。",
  pekiDescription4: "【呼ばれたい名前】",
  pekiDescription5: "ペキ",
  pekiDescription6: "【肌質】",
  pekiDescription7: "乾燥や炎症を起こしやすい敏感肌",
  pekiDescription8: "【ライフスタイル】",
  pekiDescription9: "帰宅時間の遅いオフィス勤務の社会人",
  pekiDescription10: "【どんな目線で製品紹介したいか】",
  pekiDescription11:
    "乾燥肌で悩んでて肌が弱くて普段思うように化粧が出来ない人に向けて私はこうしてますなど体験談を含めた紹介ができたらなと",

  /* ----- hamano ---- */
  hamanoTitle: "日本皮膚科学会認定 皮膚科専門医",
  hamanoName: "濱野英明",
  hamanoHistory1: "略歴",
  hamanoHistory2: "聖マリアンナ医科大学卒業",
  hamanoHistory3: "川崎市立川崎病院(神奈川県)にて内科研修",
  hamanoHistory4: "慶應義塾大学病院皮膚科入局、勤務",
  hamanoHistory5: "稲城市立病院(東京都）皮膚科勤務",
  hamanoHistory6: "済生会横浜市南部病院(神奈川県）皮膚科勤務",
  hamanoHistory7: "平成22年11月 テティス横濱美容皮膚科開設",
  hamanoAffiliated1: "所属学会",
  hamanoAffiliated2: "日本皮膚科学会（皮膚科専門医取得）",
  hamanoAffiliated3: "日本美容皮膚科学会",
  hamanoAffiliated4: "日本東洋医学会",
  hamanoAffiliated5: "日本レーザー医学会",
  hamanoAffiliated6: "日本スキンケア協会 皮膚科顧問",
  hamanoAffiliated7: "2015ミス・ユニバース ビューティーキャンプ講師",
  hamanoAffiliated8:
    "2018ミススプラインターナショナル神奈川、ビューティーキャンプ講師、審査員",
  hamanoAffiliated9: "ヒアルロン酸、ボトックス注射指導医",

  /* --------------------------- /*
  /* ---------- About ---------- */
  /* --------------------------- */
  aboutTitle1: "コンシェルジュとつくる",
  aboutTitle2: "あなただけのキレイ",
  aboutIntro1: "自分の肌タイプ",
  aboutIntro2: "を知った上で、",
  aboutIntro3: "自分の肌に合う",
  aboutIntro4: "商品・スキンケア方法を選ぶことが重要",
  aboutIntro5: "- 米国皮膚科学会 -",
  aboutChangeTopics: "でも",
  aboutQuestion1: "自分の肌タイプを",
  aboutQuestion2: "知っていますか？",
  aboutQuestion3: "今の自分の肌に合う商品を",
  aboutQuestion4: "自分で選べますか？",
  aboutQuestion5: "適切なスキンケア方法を",
  aboutQuestion6: "知っていますか？",
  aboutContinueTopics: "さらに",
  aboutQuestion7: "季節",
  aboutComma: "、",
  aboutQuestion8: "スキンケア",
  aboutQuestion9: "生活習慣",
  aboutQuestion10: "などにより",
  aboutQuestion11: "肌は常に",
  aboutQuestion12: "変化",
  aboutQuestion13: "します。",
  aboutCatchCopy1: "は",
  aboutCatchCopy2: "あなたのカルテ",
  aboutCanDoTitle1: "YUNI COSME",
  aboutCanDoTitle2: "で",
  aboutCanDoTitle3: "できること",
  aboutCanDoDesc1: "肌チェック",
  aboutCanDoDesc2:
    "質問に答えていくことで、あなたの現在の肌コンディションをチェックすることができます。",
  aboutCanDoDesc3: "スキンケア提案",
  aboutCanDoDesc4:
    "あなたの肌に合うスキンケアアイテムを、スキンケア専門のコンシェルジュがご提案します。",
  aboutCanDoDesc5: "メイク提案",
  aboutCanDoDesc6:
    "あなたに合うメイクアイテム・メイク方法を、プロメイクアップアーティストがご提案します。",
  aboutCanDoDesc7: "成分判定",
  aboutCanDoDesc8:
    "あなたの肌に合わないアイテムを記録することで、あなたの肌に合わない成分を判定します。",
  aboutMykarteDesc1: "マイカルテ",
  aboutMykarteDesc2: "あなたの肌に関する全ての情報を",
  aboutMykarteDesc3: "記録することができます。",
  aboutMykarteDesc4: "あなたの肌質",
  aboutMykarteDesc5: "肌に合うスキンケア商品",
  aboutMykarteDesc6: "あなたに合う",
  aboutMykarteDesc7: "メイク方法・商品",
  aboutMykarteDesc8: "合う成分・合わない成分",
  aboutMykarteDesc9: "今の肌の状態や",
  aboutMykarteDesc10: "コスメと肌の相性を記録して",
  aboutMykarteDesc11: "あなただけのカルテ",
  aboutMykarteDesc12: "をつくりあげましょう。",
  aboutFirstOfAll1: "まずは",
  aboutFirstOfAll2: "肌チェック",
  aboutFirstOfAll3: "スキンケア提案",
  aboutFirstOfAll4: "メイク提案",
  aboutFirstOfAll5: "成分判定",
  // ブランク2つは、デザインのために入れています。
  aboutOurPromise1: "OUR  PROMISE",
  aboutOurPromise2: "メーカー・ブランド",
  aboutOurPromise3: "から",
  aboutOurPromise4: "広告費",
  aboutOurPromise5: "をいただかない",
  aboutOurPromiseDesc1:
    "広告費をいただいてしまうと、フラットな視点で公正にアイテムを選定するということが、",
  aboutOurPromiseDesc2:
    "どうしてもできなくなってしまい、おすすめアイテムの信憑性が揺らいでしまう、といったことが起こると考えます。",
  aboutOurPromiseDesc3:
    "YUNI COSMEは、メーカー・ブランドからの広告費は一切いただかず、",
  aboutOurPromiseDesc4:
    "本当に良いアイテム、心からおすすめできるアイテムのみを、掲載していきます。",
  aboutStartSkinCheck1: "肌チェックを",
  aboutStartSkinCheck2: "はじめる",
};
